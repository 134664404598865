/*--------------------------------------------------------------
# Global Header
--------------------------------------------------------------*/


.header {
  color: var(--default-color);
  background-color: var(--background-color);
  /* padding: 5px 0; */
  transition: all 0.5s;
  z-index: 997;
}

/* General styles for the logo */
.header .logo {
  display: flex;
  align-items: center;
  text-decoration: none;
  font-size: 24px; /* Default font size */
}

.header .logo img {
  max-width: 40px; /* Default size for the logo image */
  margin-right: 8px; /* Spacing between logo and text */
}

.header .logo .sitename {
  font-size: 24px; /* Default size for the sitename */
  font-weight: bold;
}

.header .logo span {
  font-size: 24px; /* Default size for the dot */
  color: #ff6347; /* Example color for the dot */
}

/* Responsive adjustments for small screens */
@media (max-width: 768px) {
  .header  .logo {
    font-size: 18px; /* Reduce font size for smaller screens */
    margin-left: -24px;
  }

  .header .logo img {
    max-width: 55px; /* Resize the logo image for smaller screens */
  }

  .header .logo .sitename {
    font-size: 24px; /* Smaller text size for the sitename */
  }

  .header .logo span {
    font-size: 16px; /* Smaller text size for the dot */
  }
}

/* 
.header .logo {
  line-height: 1;
  font-size: 14px;
}

.header .logo img {
  height: 100px; /* Increased height */
  /* width: auto; Maintain aspect ratio 
  max-width: 100px;
  filter: drop-shadow(2px 2px 4px rgba(0, 0, 0, 0.2));
  margin-left: -80px;

 
} */

.header .logo h1 {
  font-size: 32px;
  margin: 0;
  font-weight: 700;
  color: var(--heading-color);
}

.header .logo span {
  font-size: 32px;
  font-weight: 700;
  color: var(--accent-color);
}
.header .btn-getstarted,
.header .btn-getstarted:focus {
  color: black; /* Simple black text */
  font-size: 20px;
  padding: 0; /* Remove button padding */
  margin: 0 -76px 0 30px;
  background: none; /* Remove background color */
  border: none; /* Remove border */
  transition: 0.3s;
  position: relative;
  text-decoration: none; /* Remove underline */
  text-shadow: 0 0 10px rgba(0, 0, 0, 0.2), 0 0 20px rgba(0, 0, 0, 0.3); /* Initial text shadow */
}

.header .btn-getstarted:hover {
  color: black; /* Keep text black on hover */
  background-color: #e1e1e1; /* Set background to #f9f9f9 on hover */
  padding: 10px; 
}

@media (max-width: 768px) {
  .header .logo {
    order: 1;
  }

  .header .btn-getstarted,
  .header .btn-getstarted:focus {
    order: 2;
    margin: 0 15px 0 0;
    padding: 6px 15px;
  }

  .header .navmenu {
    order: 3;
  }
}

.scrolled .header {
  box-shadow: 0px 0 18px rgba(0, 0, 0, 0.1);
}

@media (max-width: 768px) {
  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 20px; /* Add padding for spacing */
  }

  .header .logo {
    flex: 1; /* Ensures the logo takes up only necessary space */
    display: flex;
    align-items: center;
  }

  .header .btn-getstarted {
    order: 3; /* Place the button after the toggle menu */
    margin: 0;
    padding: 8px 16px;
    font-size: 14px;
  }

  .header .mobile-nav-toggle {
    order: 2;
    margin-left: 10px;
    z-index: 1000; /* Ensure it stays on top */
    position: relative;
  }

  .mobile-nav-active .mobile-nav-toggle {
    position: absolute;
    top: 15px;
    right: 15px;
  }

  /* Prevent overlap with "Book Demo" */
  .header .btn-getstarted + .mobile-nav-toggle {
    margin-left: 10px;
  }
}

/*--------------------------------------------------------------
  # Navigation Menu
  --------------------------------------------------------------*/
/* Navmenu - Desktop */
@media (min-width: 769px) {
  .navmenu {
    padding: 0;
  }

  .navmenu ul {
    margin: 0;
    padding: 0;
    display: flex;
    list-style: none;
    align-items: center;
  }

  .navmenu li {
    position: relative;
  }

  .navmenu a,
  .navmenu a:focus {
    color: var(--nav-color);
    padding: 18px 15px;
    font-size: 19px;
    font-family: var(--nav-font);
    font-weight: 500;
    display: flex;
    align-items: center;
    justify-content: space-between;
    white-space: nowrap;
    transition: 0.3s;
    margin-left: 7px;
  }

  .navmenu a i,
  .navmenu a:focus i {
    font-size: 12px;
    line-height: 0;
    margin-left: 5px;
    transition: 0.3s;
  }


  .navmenu li:hover > a,
  .navmenu .active,
  .navmenu .active:focus {
    color: var(--nav-hover-color);
  }

  
  /* Dropdown menu */
  .dropdown-menu {
    display: none;
    position: absolute;
    cursor: pointer;
    top: 100%;
    /* left: 50%; */
    transform: translateX(-50%);
    width: 900px; /* Increase width */
    background-color: white;
    border-radius: 10px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    padding: 10px;
    z-index: 1;
    grid-template-columns: repeat(3, 1fr); /* Two items per row */
    gap: 10px; /* Add spacing between items */
    display: grid;
    overflow: auto;

    box-sizing: border-box;
  }

  /* Dropdown item */
  .dropdown-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px;
    border: 2px solid #dcdcdc;
    border-radius: 8px;
    text-decoration: none; /* Ensure NavLink behaves as a link */
    color: black; /* Inherit text color */
    transition: box-shadow 0.3s ease, transform 0.3s ease;
  }

  .dropdown-item:hover {
    box-shadow: 0 4px 12px rgba(11, 11, 11, 0.2);
    transform: translateY(-3px); /* Lift on hover */
    background-color: cadetblue !important;
  }
  /* Active State */
.dropdown-item.active {
  background-color: var(--accent-color) !important; /* Background color for active */
  color: #ffffff !important; /* Font color white */
  border: 2px solid rgb(242, 237, 239); /* Add a border for emphasis */
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
  transform: scale(1.02); /* Slight scaling for focus */
}

.dropdown-item.active .title,
.dropdown-item.active .subtitle {
  color: #ffffff !important; /* Ensure all text turns white */
}

.dropdown-item.active .icon img {
  filter: brightness(0) invert(1); /* Convert image to white */
}
  /* Icon styling */
  .dropdown-item .icon img {
    width: 50px; /* Adjust width as needed */
    height: 50px; /* Adjust height as needed */
    object-fit: contain; /* Ensures proper scaling */
    margin-right: 12px;
    /* margin-left: -14px; */
  }

  /* Content within each item */
  .content {
    flex: 1;
  }

  .title {
    font-size: 20px;
    font-weight: 600;
    margin: 0;
  }

  .subtitle {
    font-size: 10px;
    color: #666;
    margin-top: 5px;
    font-weight: 600;
    line-height: 3;
  }

  /* Show dropdown menu on hover */
  .dropdown:hover .dropdown-menu {
    display: grid;
  }
}

/* Navmenu - Mobile */
@media (max-width: 768px) {

  /* When mobile nav is active */
.mobile-nav-active .navmenu > ul {
  display: block;
}

.mobile-nav-active {
  overflow: hidden;
}

.mobile-nav-toggle {
  cursor: pointer;
  font-size: 28px;
  transition: color 0.3s;
}

  .mobile-nav-toggle {
    color: var(--nav-color);
    font-size: 28px;
    line-height: 0;
    margin-right: 10px;
    cursor: pointer;
    transition: color 0.3s;
  }

  .navmenu {
    padding: 0;
    z-index: 9997;
  }

  .navmenu ul {
    display: none;
    list-style: none;
    position: absolute;
    inset: 60px 20px 20px 20px;
    padding: 10px 0;
    margin: 0;
    border-radius: 6px;
    background-color: var(--nav-mobile-background-color);
    overflow-y: auto;
    transition: 0.3s;
    z-index: 9998;
    box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.1);
  }

  .navmenu a,
  .navmenu a:focus {
    color: var(--nav-dropdown-color);
    padding: 10px 20px;
    font-family: var(--nav-font);
    font-size: 17px;
    font-weight: 500;
    display: flex;
    align-items: center;
    justify-content: space-between;
    white-space: nowrap;
    transition: 0.3s;
  }

  .navmenu a i,
  .navmenu a:focus i {
    font-size: 12px;
    line-height: 0;
    margin-left: 5px;
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    transition: 0.3s;
    background-color: color-mix(in srgb, var(--accent-color), transparent 90%);
  }

  .navmenu a i:hover,
  .navmenu a:focus i:hover {
    background-color: var(--accent-color);
    color: var(--contrast-color);
  }

  .navmenu a:hover,
  .navmenu .active,
  .navmenu .active:focus {
    color: var(--nav-dropdown-hover-color);
  }

  .navmenu .active i,
  .navmenu .active:focus i {
    background-color: var(--accent-color);
    color: var(--contrast-color);
    transform: rotate(180deg);
  }

  .navmenu .dropdown ul {
    position: static;
    display: none;
    z-index: 99;
    padding: 10px 0;
    margin: 10px 20px;
    background-color: var(--nav-dropdown-background-color);
    border: 1px solid color-mix(in srgb, var(--default-color), transparent 90%);
    box-shadow: none;
    transition: all 0.5s ease-in-out;
  }

  .navmenu .dropdown ul ul {
    background-color: rgba(33, 37, 41, 0.1);
  }

  .navmenu .dropdown > .dropdown-active {
    display: block;
    background-color: rgba(33, 37, 41, 0.03);
  }

  .mobile-nav-active {
    overflow: hidden;
  }

  .mobile-nav-active .mobile-nav-toggle {
    color: #fff;
    position: absolute;
    font-size: 32px;
    top: 15px;
    right: 15px;
    margin-right: 0;
    z-index: 9999;
  }

  .mobile-nav-active .navmenu {
    position: fixed;
    overflow: hidden;
    inset: 0;
    background: rgba(33, 37, 41, 0.8);
    transition: 0.3s;
  }

  .mobile-nav-active .navmenu > ul {
    display: block;
  }

  /* .dropdown-menu 
    /* Dropdown menu */
  .dropdown-menu {
    display: none;
    position: absolute;
    cursor: pointer;
    /* top: 50%; */
    width: 75%;
    left: 3%;
    /* transform: translateX(-50%); */
    
    background-color: white;
    border-radius: 10px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    padding: 2px 10px 0px 7px;
    z-index: 1;
    /* grid-template-columns: repeat(3, 1fr); Two items per row */
    gap: 10px;             
    display: block;
    overflow: auto;
    
    margin-top: -10px;
    box-sizing: border-box;
  }



 /* Dropdown item */
 .dropdown-item {
  margin-top: 5px;
  margin-left: 1px;

  align-items: center;
  justify-content: space-between;
  /* padding: 30px; */
  border: 2px solid #dcdcdc;
  border-radius: 6px;
  text-decoration: none; /* Ensure NavLink behaves as a link */
  color: black; /* Inherit text color */
  transition: box-shadow 0.3s ease, transform 0.3s ease;
}

.dropdown-item:hover {
  box-shadow: 0 4px 12px rgba(11, 11, 11, 0.2);
  transform: translateY(-3px); /* Lift on hover */
  background-color: cadetblue !important;
}
/* Active State */
.dropdown-item.active {
background-color: var(--accent-color) !important; /* Background color for active */
color: #ffffff !important; /* Font color white */
border: 2px solid rgb(242, 237, 239); /* Add a border for emphasis */
box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
transform: scale(1.02); /* Slight scaling for focus */
}

.dropdown-item.active .title,
.dropdown-item.active .subtitle {
color: #ffffff !important; /* Ensure all text turns white */
}

.dropdown-item.active .icon img {
filter: brightness(0) invert(1); /* Convert image to white */
}
/* Icon styling */
.dropdown-item .icon img {
  width: 30px; /* Adjust width as needed */
  height: 30px; /* Adjust height as needed */
  object-fit: contain; /* Ensures proper scaling */
  margin-right: 7px;
  margin-left:-11px;
  /* margin-left: -14px; */
}

  /* Content within each item */
  .content {
    flex: 1;
  }

  .title {
    font-size: 10px;
    font-weight: 600;
    margin-bottom: -4px;
    
  }

  .subtitle {
    font-size: 9px;
    color: #666;
    margin-top: 1px;
    font-weight: 600;
    line-height: 2;
  }

  /* Show dropdown menu on hover */
  .dropdown:hover .dropdown-menu {
    display: grid;
  }

 
}
