/* TextReveal.css */

/* Keyframe for character animation */
@keyframes revealCharacter {
  0% {
    opacity: 0;
    transform: translateX(-20px);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

.reveal-character {
  display: inline-block;
  opacity: 0;
  animation: revealCharacter 0.5s ease-out forwards;
}

/* Apply to individual characters, ensuring they animate properly */
.revealed-text {
  display: inline; /* Ensure text stays inline after animation */
  white-space: normal;
}

/* Prevent text from breaking mid-word and handle wrapping correctly */
.reveal-word {
  display: inline-block;
  white-space: nowrap; /* Prevent breaking at word boundary */
  margin-right: 5px; /* Add some space between words */
}

.word-wrap {
  white-space: normal;
  word-wrap: break-word;
  overflow-wrap: break-word;
}
/*--------------------------------------------------------------
# Communications Section
--------------------------------------------------------------*/
.container{
  padding: 20px; 
  max-width: 1300px;
}

/* General Styling */
/* Communication Section */
.tabs {
  padding: 1rem;
  text-align: center;
  background: #D8D8D8;
  cursor: pointer;
  border: none;
  transition: background 0.3s ease, color 0.3s ease, border-radius 0.3s ease;
  border-top-left-radius: 0.6rem;  /* Round top-left corner */
  border-top-right-radius: 0.6rem; /* Round top-right corner */
}


/* Active Tabs */
.active-tabs {
  background: var(--accent-color);
  color: #fff;
  border-radius: 0.6rem 0.6rem 0 0;
  pointer-events: none;
}

/* Hover Effect for Non-Active Tabs */
.tabs:not(.active-tabs):hover {
  background: #ffffff;
  color: var(--accent-color);
  border-radius: 0.6rem 0.6rem 0 0;
}

/* Tabs Container with Horizontal Scroll */
.tab-container {
  display: flex;
  /* width: 80%; */
  overflow-x: auto; /* Enable horizontal scrolling */
  white-space: nowrap; /* Prevent tabs from wrapping */
  scrollbar-width: thin; /* For modern browsers: make scrollbar thinner */
  gap: 0;
  padding: 0;
}

.tab-container::-webkit-scrollbar {
  height: 6px; /* Adjust scrollbar height */
}

.tab-container::-webkit-scrollbar-thumb {
  background-color: #ccc;
  border-radius: 3px;
}

/* Individual Tab Styling */
.tabs {
  flex-shrink: 0; /* Prevent tabs from shrinking */
  width: 16.67%; /* Set fixed width for each tab */
  text-align: center;
  font-family: 'Roboto', sans-serif;
}

.tabs.MuiTab-root {
  text-transform: capitalize;
  font-size: 20px;
  color: black;
}

.tabs.Mui-selected {
  color: var(--accent-color);
  font-weight: bold;
  text-transform: capitalize;
  font-size: 20px;
}

/* Responsive Tabs */
@media (max-width: 768px) {
  /* Allow tabs to take full width in smaller screens, still horizontally scrollable */
  .tab-container {
    width: 100%;
    overflow-x: auto;
  }
}

/* Content Area for Tabs */
.content-tabs {
  position: relative;
  background-color: var(--accent-color);
  padding: 24px;
  height: auto;
  overflow: hidden;
}

.content-tabs::before,
.content-tabs::after {
  content: '';
  position: absolute;
  border-radius: 50%;
  opacity: 0.1;
  z-index: 0;
}

.content-tabs::before {
  width: 400px;
  height: 400px;
  background-color: #ffffff;
  bottom: -20%;
  left: -50px;
}

.content-tabs::after {
  width: 800px;
  height: 800px;
  background-color: #ffffff;
  bottom: -40%;
  right: -35%;
}

.content-tabs .circle {
  position: absolute;
  width: 500px;
  height: 500px;
  background-color: #ffffff;
  border-radius: 50%;
  opacity: 0.1;
  z-index: 0;
  top: -10%;
  left: 50%;
  transform: translate(-50%, -50%);
}



.content {
  position: relative;
  z-index: 1;
}

.active-content {
  display: block;
}

/* Responsive Communication Section */
.communication-content,
.communication-image {
  flex: 1;
  margin: 0 20px;
}


.communication-image img {
  width: 100%;
  height: auto;
  object-fit: cover;
  border-radius: 15px;
}

@media (max-width: 768px) {
  .communication-content,
  .communication-image {
    max-width: 100%;
    flex: 100%; /* Stack vertically */
  }
  
  .tabs {
    width: 40%; /* Tabs take full width on smaller screens */
  }
  
  .content-tabs {
    padding: 20px;
  }

  
.content-tabs::before {
  
  bottom: 20%;
  left: -200px;
}

.content-tabs::after {
 
  bottom: -50%;
  right: -75%;
}

.content-tabs .circle {
  top: -10%;
  left: 50%;
}
}

/*--------------------------------------------------------------
# Testimonials Section
--------------------------------------------------------------*/
/* Testimonial Section */
.testimonials {
  padding: 40px;
  text-align: center;
  overflow: hidden;
  position: relative;
}

.testimonials h3 {
  display: flex;
  justify-content: space-around;
  align-content: center;
  margin-top: -31px;
  font-weight: bold;
  color: black;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3);
  font-family: 'Poppins', sans-serif;
}

.testimonial-card-wrapper {
  overflow: hidden;
  width: 100%;
}
.testimonial-card-container {
  display: flex;
  justify-content: center;
  gap: 20px;
  padding: 20px 0; /* Add padding to ensure cards don’t get chopped */
}

.testimonial-card {
  border: 3px dotted var(--accent-color);
  border-radius: 16px;
  padding: 20px;
  background: linear-gradient(135deg, #fff, #f9f9f9);
  text-align: left;
  font-family: "Poppins", sans-serif;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease, background 0.3s ease;
  cursor: pointer;
  flex: 0 0 calc(100% / 3 - 20px);
  margin: 10px 0; /* Add margin to create extra space for hover scaling */
}

@media (max-width: 768px) {
.testimonial-card {
  
  flex: 0 0 calc(100%); 
  margin-left: 15px; /* Add margin to create extra space for hover scaling */

}
}

.testimonial-card:hover {
  transform: scale(1.02);
  box-shadow: 0 12px 24px rgba(0, 0, 0, 0.2);
  background: linear-gradient(135deg, #aad3ff, #ffffff);
}

.testimonial-card-wrapper {
  overflow: visible; /* Ensure the scaling effect isn't cut off */
}


.testimonial-card blockquote {
  font-style: italic;
  font-size: 1.1rem;
  color: #666;
}

.testimonial-img {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  margin-bottom: 15px;
}

.pagination {
  display: flex;
  justify-content: center;
  gap: 10px;
  margin-top: 20px;
}

.dot {
  width: 12px;
  height: 12px;
  background: #ccc;
  border-radius: 50%;
  cursor: pointer;
  transition: background 0.3s ease;
}

.dot.active {
  background: var(--accent-color);
}

