/* Fade-in animation */
.fade-in {
    animation: fadeIn 0.8s ease-in-out forwards;
    opacity: 0; /* Start invisible */
}

@keyframes fadeIn {
    from {
        opacity: 0;
        transform: translateY(10px); /* Slight slide effect */
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}
