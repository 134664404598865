
 /*--------------------------------------------------------------
# TAB SECTIONS #ALL THREE COMPONETS BETWEEN SPACE SIMULTANEOUSLY
--------------------------------------------------------------*/
/*  */
  .container{
    padding: 20px; 
    max-width: 1300px;
  }
  
  .tabsections{
  }
  
  #overview-section,
  #benefit-section,
  #feature-section {
  }

 /*--------------------------------------------------------------
# HEADER
--------------------------------------------------------------*/

 /* Style the navigation header */
.section-header{
  position: sticky;
  top: 0; /* Fix it to the top of the viewport */
  z-index: 10; /* Ensure it stays on top of other elements */
  background-color: #ebf1f7; /* Light background */
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1); /* Subtle shadow */
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* border-bottom: 5px solid #007bff; Border for separation */
  padding: 10px 10px;

}

  .subheader {
    margin-left: 100px;
    background-color: #ebf1f7; /* Light background */
  
   }
   .subheader .btn1{
    margin-left: 600px;
  
   }


   .subheader nav {
    display: flex; /* Flexbox layout */
    align-items: center;
    width: 100%; /* Ensure full width usage */
  }
  /* Style for navigation list */
  .nav-list {
    display: flex;
    list-style: none;
    padding: 0;
    
    margin-right: 91px;
    margin-left: -75px;
    gap: 30px; /* Add space between items */
  }
  
  .nav-list li {
    position: relative; /* For absolute positioning of active indicator */
  }
  
  .nav-link {
    text-decoration: none;
    font-size: 18px;
    color: #333;
    font-weight: 500; /* Medium weight for readability */
    text-transform: uppercase; /* Uppercase for a bold look */
    padding: 10px 20px;
    transition: color 0.3s ease, transform 0.3s ease; /* Smooth transition */
    
  }
  
  .nav-link:hover {
    color: #007bff; /* Highlight color on hover */
    transform: translateY(-3px); /* Lift effect */
  }
  
  .nav-link.active {
    font-weight: bold;
    color: #007bff;
    border-bottom: 3px solid #007bff; /* Blue underline */
   
  }
  
  
  /* Add a subtle underline effect on hover */
  .nav-link:hover::after {
    content: '';
    position: absolute;
    bottom: -2px;
    left: 0;
    width: 100%;
    height: 2px;
    background-color: #515e6c;
    transform: scaleX(0);
    transform-origin: bottom right;
    transition: transform 0.3s ease-in-out;
    transform: scaleX(1);
    transform-origin: bottom left;
  }
  
  .btn1 .btn-get {
    
    background-color: #007bff; /* Blue button */
    color: white;
    /* border: none; */
    border-radius: 5px;
    padding: 10px 30px;
    font-size: 16px;
    /* text-decoration: none; */
    cursor: pointer;
    margin-left: -9px;
    white-space: nowrap;
  }
  
  .btn-get:hover {
    background-color: #0056b3; /* Darker blue on hover */
  }


/* General Section Styling */
.overview-section {
  /* padding: 40px; */
  background-color: #f9f9f9; /* Light background color */
  font-family: Arial, sans-serif;
}

/* Content Wrapper */
.overview-content {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-start; /* Align items to the top */
  max-width: 1258px;
  margin: 0 auto;
  gap: 20px;
}

/* Text Section */
.overview-text {
  flex: 1 1 40%; /* Default width for large screens */
  padding: 15px 0px 15px 0px;
  margin-top: -11px;
}

/* Title Styling */
.overview-title {
  font-size: 2.5rem;
  color: #090707;
  margin-top: 10px;
  margin-bottom: 10px; /* Positive margin to maintain proper spacing */
  font-weight: bold;
}

/* Description Styling */
.overview-description {
  font-size: 1rem;
  color: #262424;
  line-height: 1.6;
  letter-spacing: 0.2rem;
}

/* Video Section */
.overview-video {
  flex: 1 1 55%; /* Default width for large screens */
  max-width: 500px; /* Reduce the width of the video */
  margin: 0 auto; /* Center the video */
  border-radius: 8px; /* Rounded corners for the video container */
  overflow: hidden; /* Ensure the video fits within the rounded container */
  position: relative;
  background-color: #000; /* Add a background color to fill empty spaces */
  border: 4px solid #007BFF; /* Blue border for the video */
}

/* External CSS for the Wistia video player */
.wistia-player {
  background: center / contain no-repeat url('https://fast.wistia.com/embed/medias/m0s76icch8/swatch');
  display: block;
  filter: blur(5px);
  padding-top: 56.25%; /* Aspect ratio */
  position: relative;
}

/* Adjust the player container style for responsiveness */
.overview-video-container {
  position: relative;
  width: 100%;
  max-width: 800px; /* Limit the max width for better layout */
  margin: 0 auto;
}

/* Media Queries for responsiveness */

/* For screens smaller than 768px (mobile) */
@media screen and (max-width: 767px) {
  .overview-content {
    flex-direction: column; /* Stack text and video vertically */
    align-items: center; /* Center the content */
  }

  .overview-text {
    flex: 1 1 100%; /* Take up full width */
    text-align: center; /* Center the text */
    margin-top: 10px; /* Adjust top margin */
  }

  .overview-video {
    flex: 1 1 100%; /* Make video take up full width */
    max-width: 100%; /* Allow video to take the full width */
    margin-top: 20px; /* Add spacing between video and text */
  }
}

/* For larger screens, keep the current layout as is */
@media screen and (min-width: 768px) {
  .overview-content {
    flex-direction: row; /* Side-by-side layout */
    justify-content: space-between;
  }

  .overview-text {
    flex: 1 1 40%; /* 40% width on medium and larger screens */
    text-align: left; /* Align text to the left */
  }

  .overview-video {
    flex: 1 1 55%; /* 55% width for video */
    max-width: 500px; /* Keep max-width for video */
  }
}

.wistia_responsive_padding {
  padding: 56.25% 0 0 0; /* Maintain 16:9 aspect ratio */
  position: relative;
}

.wistia_responsive_wrapper {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}

.wistia_embed {
  height: 100%;
  width: 100%;
  position: relative;
}

.wistia_swatch img {
  width: 100%;
  height: 100%;
  object-fit: cover; /* Cover the video area */
  filter: blur(5px);
  transition: opacity 200ms ease-in-out;
}

/* Hide Branding Toolbar */
.wistia_toolbar {
  display: none !important;
}









.benefit-container {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  justify-content: center;
  align-items: flex-start;
  padding: 2rem;
  background: #f7f9fb;
  border-radius: 15px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  margin: 2rem auto;
  width: 90%;
  margin-right: 9px; 
  /* padding-left: 30px;
  padding-right: 3px; */

   
}

.benefit-step {
  display: flex;
  gap: 1rem;
  align-items: flex-start;
}

.benefit-number {
  font-size: 1.5rem;
  font-weight: bold;
  color: #0a74da;
  background: #eaf3fc;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;align-items: center;
}

.benefit-content {
  max-width: 700px;
}
.benefit-title {
  font-size: 1.5rem;
  color: #333;
  margin-bottom: 0.5rem;
  font-weight: bold;
}

.benefit-text {
  font-size: 1.2rem;
  color: #555;
  line-height: 1.8;
}

/* #Feature.css */
.feature-container {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  padding: 2rem;
  background: #eef4f8;
  border-radius: 20px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.15);
  margin: 2rem auto;
  max-width: 900px;
}

.feature-step {
  display: flex;
  align-items: flex-start; 
  gap: 1rem;
}

.feature-number {
  font-size: 1.5rem;
  font-weight: bold;
  color: #0078d7;
  background: #dbeeff;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.feature-content {
  flex: 1;
}
.feature-title {
  font-size: 1.6rem;
  color: #333;
  font-weight: bold;
  margin-bottom: 0.5rem;
}

.feature-text {
  font-size: 1.2rem;
  color: #555;
  line-height: 1.8;
}


  
  /* For small screens (Mobile responsiveness) */
  @media (max-width: 768px) {
  

    .overview-container {
      /* padding: 1rem; */
      height: auto;
    }
  
    .overview-content {
     
      max-width: 90%;
    }
  
    .overview-title {
      font-size: 2rem;
    }
  
    .overview-text {
      font-size: 1.2rem;
    }

    .benefit-container {
      padding: 1rem;
      margin-right: 20px;
      gap: 1rem;
      display: flex;
      justify-content: center;
      align-items: center;
   
    
    }
  
    .benefit-step {
      flex-direction: column; /* Stack number and content */
      align-items: center;
      text-align: center;
    }
  
    .benefit-number {
      width: 50px; /* Slightly bigger for better visibility */
      height: 50px;
      font-size: 1.2rem;
      margin-bottom: 0.5rem; /* Add spacing when stacked */
    }
  
    .benefit-content {
      max-width: 100%; /* Use full width on mobile */
    }
  
    .benefit-title {
      font-size: 1.3rem; /* Slightly smaller title for mobile */
    }
  
    .benefit-text {
      font-size: 1rem;
      line-height: 1.6; /* Adjust line height for smaller screens */
    }

 
/* Mobile navigation For Header */


.section-header{
  position: sticky;
  top: 0; /* Fix it to the top of the viewport */
  z-index: 10; /* Ensure it stays on top of other elements */
  background-color: #ebf1f7; /* Light background */
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1); /* Subtle shadow */
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* border-bottom: 5px solid #007bff; Border for separation */
  padding: 10px 10px;

}

  .subheader {
    margin-left: 5px;
    background-color: #ebf1f7; /* Light background */
  
   }

   .subheader nav {
    display: flex; /* Flexbox layout */
    align-items: center;
    width: 50%; /* Ensure full width usage */
  }
  /* Style for navigation list */
  .nav-list {
    display: flex;
    list-style: none;
    padding: 0;
    margin: 0;
    gap: 10px; /* Add space between items */
  }
  
  .nav-list li {
    position: relative; /* For absolute positioning of active indicator */
  }
  
  .nav-link {
    text-decoration: none;
    font-size: 15px;
    color: #333;
    font-weight: 500; /* Medium weight for readability */
    text-transform: uppercase; /* Uppercase for a bold look */
    padding: 5px 10px;
    transition: color 0.3s ease, transform 0.3s ease; /* Smooth transition */
    
  }
  
  .nav-link:hover {
    color: #007bff; /* Highlight color on hover */
    transform: translateY(-3px); /* Lift effect */
  }
  
  .nav-link.active {
    font-weight: bold;
    color: #007bff;
    border-bottom: 3px solid #007bff; /* Blue underline */
   
  }
  
  
  /* Add a subtle underline effect on hover */
  .nav-link:hover::after {
   
    position: absolute;
    bottom: -2px;
    left: 0;
    width: 50%;
    height: 2px;
    background-color: #515e6c;
    transform: scaleX(0);
    transform-origin: bottom right;
    transition: transform 0.3s ease-in-out;
    transform: scaleX(1);
    transform-origin: bottom left;
  }
  .subheader .btn1{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 15px;
    
    width: 50%;

   }
  
  .btn1 .btn-get {
    
    background-color: #007bff; /* Blue button */
    color: white;
    /* border: none; */
    border-radius: 5px;
    padding: 7px 10px;
    font-size: 13px;
    /* text-decoration: none; */
    cursor: pointer;
    white-space: nowrap;
    /* margin-right: 20px; */
   

  }
  
  .btn-get:hover {
    background-color: #0056b3; /* Darker blue on hover */
  }
}