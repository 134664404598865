
/* footer.css */
#footer {
  position: relative;
  z-index: 199; /* Highest z-index value */
}

.footer {
    color: var(--default-color);
    background-color: var(--background-color);
    font-size: 14px;
    padding-bottom: 50px;
    height: 88.5vh;
    position: relative;
  }
  
  .footer .footer-top {
    padding-top: 50px;
    border-top: 1px solid color-mix(in srgb, var(--default-color), transparent 90%);
  }
  
  .footer .footer-about .logo {
    line-height: 1;
    
    margin: -35px 0px -32px -36px;
  }
  
  .footer .footer-about .logo img {
    height: 92px;
    max-width: 92px;
    filter: drop-shadow(2px 2px 4px rgba(0, 0, 0, 0.2));
    margin-left: 6px;
  }
  

  .footer .footer-about .logo span {
    font-size: 26px;
    font-weight: 700;
    letter-spacing: 1px;
    font-family: var(--heading-font);
    color: var(--heading-color);
    margin-left: -14px;

  }
  
  .footer .footer-about p {
    font-size: 14px;
    font-family: var(--heading-font);
  }
  
  