

/*--------------------------------------------------------------
# Contact Section
--------------------------------------------------------------*/
.contact .info-item {
    background-color: var(--surface-color);
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
    padding: 24px 0 30px 0;
  }
  
  .contact .info-item i {
    font-size: 20px;
    color: var(--accent-color);
    width: 56px;
    height: 56px;
    font-size: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    transition: all 0.3s ease-in-out;
    border-radius: 50%;
    border: 2px dotted color-mix(in srgb, var(--accent-color), transparent 40%);
  }
  
  .contact .info-item h3 {
    font-size: 20px;
    color: color-mix(in srgb, var(--default-color), transparent 20%);
    font-size: 18px;
    font-weight: 700;
    margin: 10px 0;
  }
  
  .contact .info-item p {
    padding-left: 20px;
    margin-bottom: 0;
    font-size: 14px;
  }
  
  .contact .php-email-form {
    background-color: var(--surface-color);
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
    height: 100%;
    padding: 30px;
  }
  
  @media (max-width: 575px) {
    .contact .php-email-form {
      padding: 20px;
    }
  }
  
  .contact .php-email-form input[type=text],
  .contact .php-email-form input[type=email],
  .contact .php-email-form textarea {
    font-size: 14px;
    padding: 10px 15px;
    box-shadow: none;
    border-radius: 0;
    color: var(--default-color);
    background-color: var(--surface-color);
    border-color: color-mix(in srgb, var(--default-color), transparent 80%);
  }
  
  .contact .php-email-form input[type=text]:focus,
  .contact .php-email-form input[type=email]:focus,
  .contact .php-email-form textarea:focus {
    border-color: var(--accent-color);
  }
  
  .contact .php-email-form input[type=text]::placeholder,
  .contact .php-email-form input[type=email]::placeholder,
  .contact .php-email-form textarea::placeholder {
    color: color-mix(in srgb, var(--default-color), transparent 70%);
  }
  