/* Swiper container adjustments */
.mySwiper {
    padding: 1rem 0; /* Add padding around the swiper container */
  }
  
  /* Spacing for pagination dots */
  .swiper-pagination-lg {
    text-align: center;
    margin-top: 1rem; /* Space between slides and pagination dots */
  }
  
  /* Pagination dots styling */
 /* Increase the size of pagination dots */
.swiper-pagination-bullet {
    width: 16px;
    height: 16px;
    background: linear-gradient(to right, #3b82f6, #1e3a8a); /* Gradient color */
    border-radius: 50%;
    transition: all 0.3s ease; /* Smooth transition on hover */
  }
  
  /* When a dot is active */
  .swiper-pagination-bullet-active {
    background: linear-gradient(to right, #3b82f6, #1e3a8a); /* Active dot color */
    transform: scale(1.2); /* Slightly enlarge the active dot */
  }
  
  /* Hover effect for dots */
  .swiper-pagination-bullet:hover {
    background: linear-gradient(to right, #3b82f6, #1e3a8a);
    transform: scale(1.1); /* Enlarge slightly on hover */
  }
  
  /* Slide container adjustments */
  .swiper-slide > div {
    height: auto; /* Allow slides to expand naturally */
    min-height: 10rem; /* Ensure slides have enough height */
    padding: 1rem; /* Add padding inside the slide */
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
  }
  