/* Team Section */
.team .container {
  max-width: 1140px;
}

.team .row {
  display: flex;
  flex-wrap: wrap; /* Allow items to wrap as per screen size */
  justify-content: center;
  gap: 20px; /* Add spacing between items */
}

.team .team-member {
  background-color: var(--surface-color);
  overflow: hidden;
  border-radius: 5px;
  box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, filter 0.3s ease; /* Add transition for hover effects */
}

.team-member-link {
  display: block;
  text-decoration: none;
  color: inherit; /* Inherit text color */
}

.team .team-member:hover {
  transform: scale(1.05); /* Scale effect on hover */
  filter: brightness(1.2); /* Brighten effect on hover */
}

.team .team-member .member-img {
  position: relative;
  width: 100%; /* Ensure it scales with the container */
  overflow: hidden;
  aspect-ratio: 1 / 1; /* Make it square */
}

.team {
  position: relative;
  background-color: #1f2937; /* Equivalent to Tailwind's bg-gray-800 */
  padding: 100px 0; /* Adjust spacing */
  clip-path: polygon(0 calc(100px + 13px), 100% 0, 100% 100%, 0% 100%);
}


.team .team-member .member-img img {
  width: 100%;
  height: 100%; /* Ensures it fills the square container */
  object-fit: cover; /* Keeps the image proportional */
}

.team .team-member .social {
  position: absolute;
  left: 0;
  bottom: 30px;
  right: 0;
  opacity: 0;
  transition: ease-in-out 0.3s;
  text-align: center;
}

.team .team-member .social a {
  background: color-mix(in srgb, var(--contrast-color), transparent 25%);
  color: color-mix(in srgb, var(--default-color), transparent 20%);
  margin: 0 3px;
  border-radius: 4px;
  width: 36px;
  height: 36px;
  transition: ease-in-out 0.3s;
  display: inline-flex;
  justify-content: center;
  align-items: center;
}

.team .team-member .social a:hover {
  color: var(--contrast-color);
  background: var(--accent-color);
}

.team .team-member .social i {
  font-size: 18px;
  line-height: 0;
}

.team .team-member .member-info {
  padding: 25px 15px;
  text-align: center; /* Center align text for consistency */
}

.team .team-member .member-info h4 {
  font-weight: 700;
  margin-bottom: 5px;
  font-size: 18px;
}

.team .team-member .member-info span {
  display: block;
  font-size: 13px;
  font-weight: 400;
  color: color-mix(in srgb, var(--default-color), transparent 40%);
}

.team .team-member:hover .social {
  opacity: 1;
  bottom: 15px;
}

.team .team-member .social a {
  pointer-events: auto; /* Ensure links inside social div are clickable */
}

/* Responsive Breakpoints */
@media (max-width: 992px) {
  .team .row .col-lg-4 {
    flex: 0 0 50%; /* 2 cards per row on medium screens */
    max-width: 50%;
  }
}

@media (max-width: 576px) {
  .team .row .col-lg-4,
  .team .row .col-md-6 {
    flex: 0 0 100%; /* 1 card per row on small screens */
    max-width: 100%;
  }
}

/* Container styling for each team member */
.member-container {
  display: flex;
  justify-content: center; /* Horizontally center the entire row */
  align-items: center; /* Vertically align image and text */
  gap: 20px; /* Space between image and text */
  margin-bottom: 30px; /* Space between rows */
}

/* Image container */
.member-img {
  position: relative;
  overflow: hidden;
  max-width: 300px; /* Ensure consistent image size */
  margin: auto; /* Center the image within its column */
}

.member-img img {
  transition: transform 0.3s ease-in-out;
  max-width: 100%;
  height: auto;
}

.member-img:hover img {
  transform: scale(1); /* Remove zoom effect on hover */
}

/* Social icons */
.member-img .social {
  position: absolute;
  left: 0;
  bottom: 30px;
  right: 0;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
  text-align: center;
}

.member-img:hover .social {
  opacity: 1;
}

.member-img .social a {
  background: rgba(0, 0, 0, 0.25);
  color: #fff;
  margin: 0 3px;
  border-radius: 4px;
  width: 36px;
  height: 36px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  transition: all 0.3s ease-in-out;
}

.member-img .social a:hover {
  background: #007bff;
}

/* Text container */
.member-details {
  flex-grow: 1;
  padding-left: 20px; /* Add spacing between image and details */
}

.team-title {
  font-size: 1.1rem;
  font-weight: bold;
  margin-bottom: 10px;
}

.team-role {
  font-size: 1rem;
  color: #e23737;
  margin-bottom: 15px;
}

.team-description {
  font-size: 1rem;
  line-height: 1.5;
  color: #666;
}
