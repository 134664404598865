
/* Adding fade transition effect to the carousel items */
.carousel-item.fade {
  opacity: 0;
  transition: opacity 1s ease-in-out;
}

.carousel-item.active {
  opacity: 1;
}


/* Ensure the image height is preserved across screen sizes */
.carousel-image {
  height: calc(100vh - 76px);  /* Full viewport height minus the header height */
  object-fit: cover;  /* Ensure the image covers the container, maintaining aspect ratio */
}


/* On smaller devices (tablets and below), set height to auto if needed */
@media (max-width: 991px) {
  .carousel-image {
      height: auto;  /* Allow height to be auto on smaller devices */
  }
}

/* For very small screens, like mobile phones in portrait mode */
@media (max-width: 576px) {
  .carousel-image {
      height: auto;  /* Height adjusts automatically to fit the content */
  }
}


/* General Section Styling */
.about-section {
  padding: 40px;
   /* Light background color */
  font-family: Arial, sans-serif;
}

/* Content Wrapper */
.about-content {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  max-width: 1200px;
  margin: 0 auto;
  gap: 20px;
}

/* Text Section */
.about-text {
  flex: 1 1 40%;
  text-align: left;
  padding: 20px;
}

.about-title {
  font-size: 3rem;
  color: #090707;
  margin-bottom: 10px;
  font-weight: bold;
}

.about-description {
  font-size: 1rem;
  color: #262424;
  line-height: 1.6;
}

/* Video Section */
.about-video {
  flex: 1 1 55%;
  max-width: 500px; /* Reduce the width of the video */
  margin: 0 auto; /* Center the video */
  border-radius: 8px; /* Rounded corners for the video container */
  overflow: hidden; /* Ensure the video fits within the rounded container */
  position: relative;
  background-color: #000; /* Add a background color to fill empty spaces */
  border: 4px solid #007BFF; /* Blue border for the video */
}

/* External CSS for the Wistia video player */
.wistia-player {
  background: center / contain no-repeat url('https://fast.wistia.com/embed/medias/m0s76icch8/swatch');
  display: block;
  filter: blur(5px);
  padding-top: 56.25%; /* Aspect ratio */
  position: relative;
}

/* Adjust the player container style for responsiveness */
.about-video-container {
  position: relative;
  width: 100%;
  max-width: 800px; /* Limit the max width for better layout */
  margin: 0 auto;
}

.wistia_responsive_padding {
  padding: 56.25% 0 0 0; /* Maintain 16:9 aspect ratio */
  position: relative;
}

.wistia_responsive_wrapper {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}

.wistia_embed {
  height: 100%;
  width: 100%;
  position: relative;
}

.wistia_swatch img {
  width: 100%;
  height: 100%;
  object-fit: cover; /* Cover the video area */
  filter: blur(5px);
  transition: opacity 200ms ease-in-out;
}

/* Hide Branding Toolbar */
.wistia_toolbar {
  display: none !important;
}


/* About Us Section */
.image-about {
  padding-right: 20px;
  padding-left: 20px;
  padding-top: 40px;
  padding-bottom: 10px;
  font-family: 'Arial', sans-serif;
  color: #333; /* Dark text color for readability */
}

/* Wrapper for Content */
.image-about .about-content {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between; /* Distribute text evenly */
  align-items: flex-start; /* Align items at the top */
  max-width: 1200px;
  margin: 0 auto; /* Center the content */
  gap: 40px; /* Add spacing between columns */
}

/* About Group (Icon + Text) */
.image-about .about-group {
  display: flex;
  flex: 1 1 48%; /* Make each group take half width of the row */
  align-items: center;
  gap: 20px;
  margin-bottom: 30px;
}

/* Icon Styling */
.image-about .about-icon {
  font-size: 2.5rem; /* Adjust icon size */
  flex-shrink: 0; /* Prevent the icon from shrinking */
}

/* Text Blocks */
.image-about .about-text {
  flex-grow: 1;
  font-size: 1rem;
  line-height: 1.8;
}

/* Title Styling */
.image-about .about-title {
  font-size: 28px;
  color: #000; /* Darker title for emphasis */
  margin-bottom: 30px;
  font-weight: bold;
  text-align: center; /* Center the title */
}

/* Highlighted Text */

/* Responsive Styling */
@media (max-width: 768px) {
  /* Stack the groups on small screens */
  .image-about .about-group {
    flex-direction: column; /* Stack icon and text vertically */
    align-items: flex-start; /* Align content to the start */
    width: 100%; /* Take full width */
  }

  /* Adjust icon size for smaller screens */
  .image-about .about-icon {
    font-size: 2rem;
  }

  /* Adjust text padding for smaller screens */
  .image-about .about-text {
    padding: 0 20px; /* Add some padding for better readability */
  }
}

/* CULTURE SECTION*/
/* Culture Section */
.culture-section {
  padding: 50px 20px;
  font-family: 'Arial', sans-serif;
  background-color: #f9f9f9;
  color: #333; /* Dark text color for readability */
}

/* Wrapper for Content */
.culture-section .culture-content {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between; /* Distribute text evenly */
  align-items: flex-start; /* Align items at the top */
  max-width: 1200px;
  margin: 0 auto; /* Center the content */
  gap: 40px; /* Add spacing between columns */
}

/* Culture Group (Icon + Text) */
.culture-section .culture-group {
  display: flex;
  flex: 1 1 48%; /* Make each group take half width of the row */
  align-items: center;
  gap: 20px;
  margin-bottom: 30px;
}

/* Icon Styling */
.culture-section .culture-icon {
  font-size: 2.5rem; /* Adjust icon size */
  color: #333; /* Dark icon color */
  flex-shrink: 0; /* Prevent the icon from shrinking */
}

/* Text Blocks */
.culture-section .culture-text {
  flex-grow: 1;
  font-size: 1rem;
  line-height: 1.8;
}

/* Title Styling */
.culture-section .culture-title {
  font-size: 2.5rem;
  color: #000; /* Darker title for emphasis */
  margin-bottom: 30px;
  font-weight: bold;
  text-align: center; /* Center the title */
}

/* Highlighted Text */
.culture-section .culture-text strong {
  color: #d9534f; /* Red highlight for emphasis */
  font-weight: 600;
}

/* Responsive Styling */
@media (max-width: 768px) {
  /* Stack the groups on small screens */
  .culture-section .culture-group {
    flex-direction: column; /* Stack icon and text vertically */
    align-items: flex-start; /* Align content to the start */
    width: 100%; /* Take full width */
  }

  /* Adjust icon size for smaller screens */
  .culture-section .culture-icon {
    font-size: 2rem;
  }

  /* Adjust text padding for smaller screens */
  .culture-section .culture-text {
    padding: 0 20px; /* Add some padding for better readability */
  }
}


/* Section Layout */
.our-people-section {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 50px;
  background-color: #fff;
  gap: 150px;
}

/* Circle Container */
.circle-container {
  position: relative;
  width: 450px;
  height: 450px;
  border-radius: 50%;
  border: 3px solid #ddd; /* Outer round circle */
  display: flex;
  justify-content: center;
  align-items: center;

  margin-left: 50px;
}

/* Outer Circle */
.outer-circle {
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}


/* Outer Circle Items */
.circle-item {
  position: absolute;
  width: 120px;
  height: 120px;
  border-radius: 50%;
  overflow: hidden;
  background: #fff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}
.circle-item1 {
  position: absolute;
  width: 140px;
  height: 140px;
  border-radius: 50%;
  overflow: hidden;
  background: #fff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.circle-item img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 50%;
  
}
.circle-item1 img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 50%;
  
}

.center-circle {
  position: absolute;
  width: 200px;
  height: 200px;
  border-radius: 50%; /* Makes it a circle */
  overflow: hidden; /* Ensures image stays within the circle */
  background: #fff;
  /* box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); Optional shadow for the circle */
  border: 2px solid #ddd; /* Add a border line to the circle */
  padding: 8px; /* Space between circle and image */
  display: flex; /* Enables flexbox */
  justify-content: center; /* Centers image horizontally */
  align-items: center; /* Centers image vertically */
}

.center-circle img {
  width: 50%;
  height: 50%;
  object-fit: cover;
  border-radius: 50%;
}

/* Text Content */
/* Text Content */
.text-content {
  max-width: 400px;
  text-align: left;
}

.text-content h2 {
  font-size: 2.5rem; /* Larger font size */
  color: #000; /* Darker color for better visibility */
  margin-bottom: 30px;
  font-weight: bold;
  text-align: center; /* Center-align the title */
}

.text-content p {
  font-size: 1rem;
  line-height: 1.8; /* Increase line height for better readability */
  color: #555; /* Medium gray for paragraph text */
  margin-bottom: 20px; /* More spacing between paragraphs */
}

/* Media Query for smaller screens */
@media (max-width: 768px) {
  .text-content {
    max-width: 100%; /* Full width for smaller screens */
    padding: 10px;
    text-align: center; /* Center-align text */
  }

  .text-content h2 {
    font-size: 2rem; /* Smaller title for mobile screens */
  }

  .text-content p {
    font-size: 0.875rem; /* Smaller text for mobile */
    line-height: 1.6; /* Adjust line height for smaller screens */
  }
}
